import {CommonModule, CurrencyPipe, DatePipe, NgOptimizedImage} from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { ModalComponent } from "./modal/modal.component";
import { SearchNotFoundComponent } from "./search-not-found/search-not-found.component";
import { SearchComponent } from "./search/search.component";
import { TableComponent } from "./table/table.component";
import { ToasterComponent } from "./toaster/toaster.component";
import { YearDropDownComponent } from "./year-drop-down/year-drop-down.component";
import { ProgressComponent } from "./progress/progress.component";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { TabComponent } from "./tabs-group/tab/tab.component";
import { TabsGroupComponent } from "./tabs-group/tabs-group.component";
import { DropupComponent } from "./dropup/dropup.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { LocalizedNumberInputComponent } from "./localized-number-input/localized-number-input.component";
import { NumberToLocalePipe } from "./pipes/number-to-local.pipe";

@NgModule({
  declarations: [
    TabComponent,
    TabsGroupComponent,
    TableComponent,
    SearchComponent,
    ToasterComponent,
    ModalComponent,
    SearchNotFoundComponent,
    YearDropDownComponent,
    ProgressComponent,
    DropdownComponent,
    ClickOutsideDirective,
    DropupComponent,
    TooltipComponent,
    LocalizedNumberInputComponent,
    NumberToLocalePipe,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule.forChild({
            defaultLanguage: "en",
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule,
        NgOptimizedImage
    ],
  exports: [
    TabComponent,
    TabsGroupComponent,
    TableComponent,
    SearchComponent,
    ToasterComponent,
    ModalComponent,
    SearchNotFoundComponent,
    YearDropDownComponent,
    ProgressComponent,
    DropdownComponent,
    ClickOutsideDirective,
    DropupComponent,
    TooltipComponent,
    NumberToLocalePipe,
    LocalizedNumberInputComponent
  ],
  providers: [DatePipe, CurrencyPipe, NumberToLocalePipe]
})
export class SharedModule {}
