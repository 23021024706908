/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  forwardRef,
  inject, input,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguageService } from '../../@AppService/services/language.service';
import { NumberToLocalePipe } from '../pipes/number-to-local.pipe';

@Component({
  selector: 'app-localized-number-input',
  templateUrl: './localized-number-input.component.html',
  styleUrl: './localized-number-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LocalizedNumberInputComponent),
    },
  ],
})
export class LocalizedNumberInputComponent implements ControlValueAccessor {
  private numberToLocalePipe = inject(NumberToLocalePipe);
  languageService = inject(LanguageService);

  @Input() placeholder: string;
  @Input() appliedClasses = '';
  @Input() appliedStyles = '';
  clearValueOnDisabled = input(false)
  isDisabled = input(false);

  displayValue: string = '';
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.displayValue = this.formatValue(value);
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const formattedValue = this.parseValue(input.value);
    this.displayValue = input.value;
    this.onChange(formattedValue);
  }

  onBlur(): void {
    this.onTouched();
  }

  private formatValue(value: string): string {
    if (!value) {
      return '';
    }
    return this.numberToLocalePipe.transform(Number(value));
  }

  private parseValue(value: string): number {
    const lang = this.languageService.getCurrentLanguage();
    switch (lang) {
    case 'en':
      return Number(value.replace(/,/g, ''));
    default:
      return Number(value.replace(/\./g, '').replace(',', '.'));
    }
  }
}
